import useSWR from "swr";
import BigNumber from "bignumber.js";
const fetcher = (url: string) => fetch(url).then((r) => r.json());

interface Tokens {
  image: string;
  marketCap: number;
  name: string;
  id: string;
  symbol: string;
}
interface Pair {
  priceUsd: string;
}
interface FechData {
  pairs: Pair[];
}

const TOKEN_NAME = "noctis";

export default function useNoctis(): Tokens | null {
  const { data } = useSWR<FechData>(
    "https://api.dexscreener.com/latest/dex/pairs/solana/8dYuaXtudWJqb8tCnHzAWANcZb39Gf9f8HegThz9qUFB,0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae",
    fetcher,
    {
      dedupingInterval: 3600000, // Only call every our
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    image: "",
    marketCap: data?.pairs
      ? BigNumber(data?.pairs[0].priceUsd)
          .multipliedBy("666666666666")
          .toNumber()
      : 10,
    name: "noctis",
    id: "noctis",
    symbol: "NOC",
  };
}
