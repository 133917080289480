import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { SWRConfig } from "swr";

import About from "./components/about/about";
import Footer from "./components/footer/footer";
import Game from "./components/game/game";
import Header from "./components/header/header";
import Presale from "./components/presale/presale";
import Progress from "./components/progress/progress";
import Tokenomics from "./components/tokenomics/tokenomics";

import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
ReactGA.initialize("G-L1PZDS534J");

function localStorageProvider(): Map<any, any> {
  const lastTimestamp = parseInt(localStorage.getItem("last-update") || "0");
  const timestamp = Date.now();

  // One hour of cache
  if (timestamp - lastTimestamp > 1000 * 3600) {
    localStorage.removeItem("app-cache");
    localStorage.removeItem("last-update");
  }
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("app-cache", appCache);
    localStorage.setItem("last-update", Date.now().toString());
  });

  // We still use the map for write & read for performance.
  return map;
}

root.render(
  <React.StrictMode>
    <SWRConfig value={{ provider: localStorageProvider }}>
      <Header></Header>
      <Game></Game>
      <Progress></Progress>
      <Presale></Presale>
      <Tokenomics></Tokenomics>
      <About></About>
      <Footer></Footer>
    </SWRConfig>
  </React.StrictMode>
);
