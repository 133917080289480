import useSWR from "swr";
import formatNumber from "./useFormatNumber";

const fetcher = (url: string) => fetch(url).then((r) => r.json());

interface Tokens {
  image: string;
  marketCap: number | string;
  rawMarketCap: number;
  name: string;
  id: string;
  symbol: string;
}

interface FechData {
  image: string;
  market_cap: number;
  current_price: number;
  price_change_24h: string;
  name: string;
  id: string;
  symbol: string;
}

export default function useMarket({
  tokenId,
  marketCap,
  size,
}: {
  tokenId: string;
  marketCap: number;
  size: number;
}): { next: Tokens[]; back: Tokens[] } {
  const { data } = useSWR<FechData[]>(
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&category=meme-token&per_page=250",
    fetcher,
    {
      dedupingInterval: 3600000, // Only call every hour
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const cleanData = data?.filter((items) => items.id !== tokenId);
  // FILTER
  const filterBigData = cleanData?.filter((info) => {
    if (info.market_cap > marketCap) return true;
    return false;
  });

  const filterLowData = cleanData?.filter((info) => {
    if (info.market_cap > marketCap) return false;
    return true;
  });

  // PROCESS
  const nextTokens: Tokens[] =
    filterBigData
      ?.slice(Math.max(filterBigData.length - size, 0))
      .reverse()
      .map((info) => {
        return {
          id: info.id,
          name: info.name,
          marketCap: formatNumber(info.market_cap),
          rawMarketCap: info.market_cap,
          image: info.image,
          symbol: info.symbol.toUpperCase(),
        };
      }) || [];

  const backTokens: Tokens[] =
    filterLowData?.slice(0, size).map((info) => {
      return {
        id: info.id,
        name: info.name,
        marketCap: formatNumber(info.market_cap),
        rawMarketCap: info.market_cap,
        image: info.image,
        symbol: info.symbol.toUpperCase(),
      };
    }) || [];

  return { next: nextTokens, back: backTokens };
}
