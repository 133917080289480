import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row justify-center pb-40">
          <div className="col-lg-12 text-center">
            <span className="footer-txt">
              © 2024 by Noctis. All rights reserved!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
