import Noctis from "../../assets/img/character.svg";
import DoorClose from "../../assets/img/closed-door.svg";
import DoorOpenBlood from "../../assets/img/open-door-blood.svg";
import useMarket from "../../hooks/useMarket";
import useNoctis from "../../hooks/useNoctisMarket";

import "./game.scss";

const Game = () => {
  const noctis = useNoctis();
  const data = useMarket({
    marketCap: noctis?.marketCap || 0,
    size: 4,
    tokenId: noctis?.id || "noctis",
  });

  return (
    <div className="game">
      <div className="container-fluid">
        <div className="row justify-around">
          <div className="d-none d-md-block col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
            <div className="wall">
              <div className="row justify-center">
                <div className="col-12 pl-0 pr-0 justify-center pb-20">
                  <div className="relative">
                    <div className="crypto-room-img">
                      <img className="meme-logo" src={data.back[1]?.image} />
                      <div className="meme-signal">
                        <span className="meme-symbol">
                          ${data.back[1]?.symbol}
                        </span>
                        <br />
                        <span className="meme-mc">
                          ${data.back[1]?.marketCap}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 justify-center">
                  <img className="door-img" src={DoorOpenBlood} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-xxl-block col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
            <div className="wall">
              <div className="row justify-center">
                <div className="col-12 pl-0 pr-0 justify-center pb-20">
                  <div className="relative">
                    <div className="crypto-room-img">
                      <img className="meme-logo" src={data.back[0]?.image} />
                      <div className="meme-signal">
                        <span className="meme-symbol">
                          ${data.back[0]?.symbol}
                        </span>
                        <br />
                        <span className="meme-mc">
                          ${data.back[0]?.marketCap}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 justify-center">
                  <img className="door-img" src={DoorOpenBlood} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
            <div className="wall">
              <div className="row justify-center">
                <div className="col-12 pl-0 pr-0 justify-center pb-20">
                  <div className="relative">
                    <div className="crypto-room-img">
                      <img className="meme-logo" src={data.next[0]?.image} />
                      <div className="meme-signal">
                        <span className="meme-symbol">
                          ${data.next[0]?.symbol}
                        </span>
                        <br />
                        <span className="meme-mc">
                          ${data.next[0]?.marketCap}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 justify-center">
                  <div className="noctis">
                    <img className="noctis-img" src={Noctis} />
                  </div>
                  <img className="door-img" src={DoorClose} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
            <div className="wall">
              <div className="row justify-center">
                <div className="col-12 pl-0 pr-0 justify-center pb-20">
                  <div className="relative">
                    <div className="crypto-room-img">
                      <img className="meme-logo" src={data.next[1]?.image} />
                      <div className="meme-signal">
                        <span className="meme-symbol">
                          ${data.next[1]?.symbol}
                        </span>
                        <br />
                        <span className="meme-mc">
                          ${data.next[1]?.marketCap}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 justify-center">
                  <img className="door-img" src={DoorClose} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
