export default function formatNumber(num: number, precision: number = 2) {
  if (!num) {
    return 0;
  }

  const map = [
    { suffix: "T", th: 1e12 },
    { suffix: "B", th: 1e9 },
    { suffix: "M", th: 1e6 },
    { suffix: "K", th: 1e3 },
    { suffix: "", th: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.th);

  if (found) {
    const formatted = (num / found.th).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}
