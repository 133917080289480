import { useCopyToClipboard } from "@uidotdev/usehooks";
import SkullLogo from "../../assets/img/skull-rounded.svg";
import NoctisLogo from "../../assets/img/noctis-logo.svg";

import useBalance from "../../hooks/useBalance";
import Eyes from "./eyes";
import "./presale.scss";

const WALLET = "8B9751hJAzQBVhUDxvj7xKQCgv5yhEaFe4amceWxdhVp";

const Presale = () => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const hasCopiedText = Boolean(copiedText);
  const raised = useBalance(WALLET);
  return (
    <div className="presale">
      <div className="container">
        <div className="row justify-center align-center pt-30">
          <div className="col-7 col-sm-4 col-lg-3 pt-30 pb-10 justify-center">
            <img className="skull-logo" src={SkullLogo} />
            <Eyes />
          </div>
          <div className="col-12 col-sm-8 col-lg-6 pt-30 pb-10 justify-center">
            <img className="noctis-logo" src={NoctisLogo} />
          </div>
        </div>

        <div className="row justify-center pb-40">
          <div className="col-lg-12">
            <div className="row pt-20">
              <div className="col-lg-12 text-center">
                <span className="title">
                  <span className="neonText">🎉 Raised : 1101.61 SOL 🎉</span>
                </span>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-3">
            <div className="row pt-30">
              <div className="col-lg-12 text-center">
                <span className="title-live">No Softcap</span>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="row pt-30">
              <div className="col-lg-12 text-center">
                <span className="title-live">No Hardcap</span>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="row pt-30">
              <div className="col-lg-12 text-center">
                <span className="title-live">100% to Liquidity</span>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="row pt-30">
              <div className="col-lg-12 text-center">
                <span className="title-live">100% LPs Burned</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presale;
