import "./about.scss";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="container-fluid">
        <div className="row pt-80 pb-80 justify-center">
          <div className="col-lg-5 pb-40">
            <div className="row pb-20">
              <div className="col-lg-12 text-center">
                <span className="title">Who is Noctis?</span>
              </div>
            </div>
            <div className="about-box">
              <div className="row justify-center"></div>
              <div className="row">
                <div className="col-lg-12">
                  <span className="subtitle">
                    "Story of Noctis? Does Noctis have a good story?" <br />
                    <br /> No, he doesn't! So, what is this section of the
                    webpage about? <br />
                    <br /> Well, that's a good question! <br />
                    <br /> The story of Noctis is our story, your story. <br />
                    <br /> Every door opened will be our success as a $NOCTIS
                    Community! <br />
                    <br /> Welcome to Noctis, friend! Let's open our first door
                    today!
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 pb-40">
            <div className="row pb-20">
              <div className="col-lg-12 text-center">
                <span className="title">About Noctis</span>
              </div>
            </div>
            <div className="about-box">
              <div className="row">
                <div className="col-lg-12">
                  <span className="subtitle">
                    What is $NOCTIS about? Well, it's a MEME... <br />
                    <br />
                    The strategy is simple:
                    <br />
                    1st: Community growth
                    <br />
                    2nd: Market cap growth as well
                    <br />
                    3rd: Doors start opening
                    <br />
                    4th: We kill other MEMEs
                    <br />
                    5th: We seek out the next target
                    <br />
                    <br />
                    We're here to benefit the crypto community, not to harm any
                    other projects! Let's make the crypto space even funnier!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
