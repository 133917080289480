import { useEffect, useState } from "react";
import { Connection, PublicKey } from "@solana/web3.js";
const rpcSolana = new Connection(
  "https://greatest-weathered-mansion.solana-mainnet.quiknode.pro/058b23011010e3b1eb515fb1e3025b8c727db5e3/"
);

export default function useBalance(address: string) {
  const [value, setValue] = useState(
    localStorage.getItem("balance-wallet") || "0"
  );

  useEffect(() => {
    const lastUpdate = parseInt(
      localStorage.getItem("last-update-value") || "0"
    );
    if (address && Date.now() - lastUpdate > 1000 * 120) {
      rpcSolana.getBalance(new PublicKey(address)).then((data: number) => {
        localStorage.setItem("last-update-value", Date.now().toString());
        localStorage.setItem("balance-wallet", (data / 10 ** 9).toFixed(2));
        setValue((data / 10 ** 9).toFixed(2));
      });
    }
    return;
  }, [address]);
  return value;
}
