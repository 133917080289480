import { useState } from "react";
import useMightyMouse from "react-hook-mighty-mouse";
import "./eyes.scss";
function Eyes() {
  const [tiredness, setTiredness] = useState(0);
  let angleLeftEye = 0;
  let angleRightEye = 0;
  const {
    selectedElement: {
      position: { angle: _angleLeftEye },
    },
  } = useMightyMouse(true, "left-eye", { x: 45, y: 45 });
  const {
    selectedElement: {
      position: { angle: _angleRightEye },
    },
  } = useMightyMouse(true, "right-eye", { x: 45, y: 45 });
  angleLeftEye = _angleLeftEye || 0;
  angleRightEye = _angleRightEye || 0;
  const redEye =
    (angleLeftEye < 30 || (angleLeftEye < 360 && angleLeftEye > 330)) &&
    angleRightEye > 150 &&
    angleRightEye < 210;
  const styleLeftEye = {
    transform: `rotate(${-angleLeftEye}deg)`,
    backgroundColor: "transparent",
    transition: `all ${tiredness}s ease`,
  };
  const styleRightEye = {
    transform: `rotate(${-angleRightEye}deg)`,
    backgroundColor: "transparent",
    transition: `all ${tiredness}s ease`,
  };

  return (
    <div className="eyes-follow-tired">
      <div className="eyes-container">
        <div className="eyes">
          <div id="left-eye" className="eye" style={styleLeftEye}>
            <div className="pupil" />
          </div>
          <div id="right-eye" className="eye" style={styleRightEye}>
            <div className="pupil" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eyes;
