import NoctisFlying from "../../assets/img/character-2.svg";
import TokenomicsImg from "../../assets/img/tokenomics.svg";

import "./tokenomics.scss";

const Tokenomics = () => {
  return (
    <div className="tokenomics pt-20 pb-10" id="noctinomics">
      <div className="container">
        <div className="row pt-40">
          <div className="col-lg-12 text-center">
            <span className="title">Noctinomics</span>
          </div>
        </div>
        <div className="row space-around align-center">
          <div className="col-8 col-md-5 col-lg-4 pt-50">
            <img className="rrss-logo telegram" src={NoctisFlying} />
          </div>
          <div className="col-12 col-md-7 col-lg-6 pt-50">
            <img className="rrss-logo telegram" src={TokenomicsImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
