import formatNumber from "../../hooks/useFormatNumber";
import useMarket from "../../hooks/useMarket";
import useNoctis from "../../hooks/useNoctisMarket";

import "./progress.scss";

const Progress = () => {
  // The most closed

  const noctis = useNoctis();
  const token = useMarket({
    marketCap: noctis?.marketCap || 0,
    size: 1,
    tokenId: noctis?.id || "noctis",
  });

  return (
    <div className="progress-bar pt-80 pb-30">
      <div className="container">
        <div className="row pt-80 pb-80">
          <div className="col-12">
            <div className="range">
              <input
                type="range"
                min={token?.back[0]?.rawMarketCap}
                max={token?.next[0]?.rawMarketCap}
                value={noctis?.marketCap ? noctis.marketCap : 0}
                className="skull"
                disabled
              />
            </div>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="float-left amounts">
                  $ {token?.back[0]?.marketCap}
                </div>
              </div>
              <div className="col-6">
                <div className="float-right amounts">
                  $ {token?.next[0]?.marketCap}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
