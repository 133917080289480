import NoctisLogo from "../../assets/img/noctis-logo.svg";
import TelegramLogo from "../../assets/img/telegram-icon.svg";
import TwitterLogo from "../../assets/img/twitter-icon.svg";
import DexScreenerLogo from "../../assets/img/dexscreener.png";
import DextoolsLogo from "../../assets/img/dextools.svg";

import "./header.scss";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img className="header-logo" src={NoctisLogo} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav m-auto align-center">
            <li className="nav-item">
              <a className="nav-link" href="#noctinomics">
                Noctinomics
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn-buy"
                href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=GFcGsxMsSYxsWPYv63741FZ9WvEV1e4zvhywcGTAP3Ei&outputSymbol=NOCTIS&inputAmount=0.01&fixed=in"
                target="_blank"
              >
                Buy $NOCTIS
              </a>
            </li>
          </ul>
          <div className="d-flex justify-center ptb-20">
            <a
              href="https://www.dextools.io/app/en/solana/pair-explorer/8dYuaXtudWJqb8tCnHzAWANcZb39Gf9f8HegThz9qUFB"
              target="_blank"
            >
              <div className="rrss-container justify-center align-center">
                <img className="rrss-logo dextools" src={DextoolsLogo} />
              </div>
            </a>
            <a
              href="https://dexscreener.com/solana/GFcGsxMsSYxsWPYv63741FZ9WvEV1e4zvhywcGTAP3Ei"
              target="_blank"
            >
              <div className="rrss-container justify-center align-center">
                <img className="rrss-logo dexscreen" src={DexScreenerLogo} />
              </div>
            </a>

            <a href="https://t.me/NoctisCoin" target="_blank">
              <div className="rrss-container justify-center align-center">
                <img className="rrss-logo telegram" src={TelegramLogo} />
              </div>
            </a>

            <a href="https://x.com/NoctisCoinWtf" target="_blank">
              <div className="rrss-container justify-center align-center">
                <img className="rrss-logo" src={TwitterLogo} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
